import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = 'http://localhost:8000/api/';

//server hosting
const API_URL = '/api/';


class UserService {


  getPublicContent() {
    return axios.get(API_URL + 'all');
  }





  getUserCarrito() {
   
    return axios.get(API_URL + 'ingresos', { headers: authHeader() });

  }




  getUserBoard() {
   
    return axios.get(API_URL + 'me', { headers: authHeader() });

  }



  reportarIngreso(e) {
   

   // console.log(e)
    return axios.post(API_URL + 'ingresos', e);

  }







  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'ad', { headers: authHeader() });
  }

  getAdminUserBoard(e) {
    return axios.post(API_URL + 'aduser',{uuid:e} ,{ headers: authHeader() });
  }



}

export default new UserService();
