import React, { Component } from "react";
import userService from "../services/user.service";

import { Link } from "react-router-dom";

let curr = new Date();
curr.setDate(curr.getDate() + 3);
let fecha = curr.toISOString().substring(0,10);








export default class Pagar extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          userid:"",
          email:"",
          telefono:"",
          idcurso:"",
          fechadeposito:fecha,
        titular:"",
          tipopago:"Seleccionar",
          monto:"",
          bancoemisor:"",
          nombrecompleto:"",
          nota:"",
          tipoconcepto:"4",
          vauche:null,
          faq:"",
          horario: ['uno', 'dos', 'tres', 'cuatro'],
          pagobs:0,
          pagousd:0,
          message: "",
          successful: false,
          descuento:0,
          boton:false,
          titulo:"",
      


        };
      }


      componentDidMount() {


        if(this.props.curso.cuota ==="mes"){

          this.setState({tipoconcepto:"2"})
        }

        let obtenerCurso = this.props.curso.codigocurso

        let obtenerTitulo = this.props.curso.titulo

  
        let obtenerUserid = this.props.currentUser.uuid
       
        let obtenerNombre = this.props.currentUser.name
       
        let obtenerEmail = this.props.currentUser.email
      
        let obtenertfl = this.props.currentUser.telefono

        let obtenerdescuento = this.props.curso.descuento

  
      //  console.log(obtenerUserid)

        this.setState({idcurso:obtenerCurso,titulo:obtenerTitulo, userid:obtenerUserid, nombrecompleto:obtenerNombre, email:obtenerEmail, telefono:obtenertfl})
  
  
  /*             let convertiArray = this.props.curso.horario.split(',')
               
             this.setState({horario: [convertiArray]})
          
      console.log(this.state.horario) */
        
  }





      montopagar = () =>{


        let total = this.props.curso.totalusd
        let totalcondescuento = this.props.curso.totalusd - (this.props.curso.totalusd*this.props.curso.descuento/100 ) + this.props.curso.costoinscrip


        if(this.props.curso.descuento>0){

          total = totalcondescuento

        }

        if(this.props.curso.cuota ==="mes"){

          total = this.props.curso.costousd + this.props.curso.costoinscrip
        }

      const montoconcepto =   ["-",this.props.curso.costoinscrip,this.props.curso.costoinscrip + this.props.curso.costousd,this.props.curso.costousd,total]

      const concepto =    ["-","Inscripción","Inscripción + 1ra Cuota","Cuota Mensual", "Totalidad"]



      return <table className="table table-sm table-dark">
      <thead>
        <tr>
          <th scope="col-sd-8">Concepto</th>
          <th scope="col-sd-4">Monto</th>
        </tr>
      </thead>
      <tbody>
       
        <tr>
          <td >{concepto[this.state.tipoconcepto] } </td>
    
          <td>{montoconcepto[this.state.tipoconcepto]} $</td>
        </tr>
      </tbody>
    </table>

      }
    

      onChange(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
    }    



      enviarpago = (e) =>{



        e.preventDefault();
      
      

this.setState({boton:true})

if(this.state.tipopago !== "Seleccionar")   
     {  
      
      
      const concepto =    ["-","Inscripción","Inscripción + 1ra Cuota","Cuota Mensual", "Totalidad"]

      
      
      userService.reportarIngreso({
          idcurso:this.state.idcurso,
          userid:this.state.userid,
          email:this.state.email,
          fechadeposito:this.state.fechadeposito,
          tipopago:this.state.tipopago,
          titular:this.state.titular,
          cuota:this.state.cuota,
          vauche:this.state.vauche,
          pagobs:this.state.pagobs,
          pagousd:this.state.pagousd,
          bancoemisor:this.state.bancoemisor,
          nombrecompleto:this.state.nombrecompleto,
          nota:this.state.nota,
          telefono:this.state.telefono,
          concepto:concepto[this.state.tipoconcepto],
          reporte:this.props.reporta,
          titulo:this.state.titulo


        }).then(
          response =>  {

            this.setState({successful:true})
          


           
            }); 



            }else{

    this.setState({message:"Debe especificar la forma de pago"})



            }

      }





    render() {

        const { tipopago } = this.state;
  // console.log(this.props.curso)
   return <div>

{this.state.successful===false?
<div className="row ">
<div className="card container">
<form   onSubmit={this.enviarpago}>
<h3>Planilla de Pagos</h3>
<div className="form-group">
                  <label htmlFor="concepto">Concepto de Pago:</label>
                 <select className="form-control"
                    name="tipoconcepto"
                    value={this.state.tipoconcepto || null}
                    onChange={this.onChange.bind(this)}>

              
              
{this.props.curso.costoinscrip &&                <option value="2"> Inscripción + 1ra Cuota</option>
}{this.props.curso.costoinscrip &&                <option value="1"> Inscripción</option>
}                

{this.props.curso.costousd &&<option value="3"> Cuota Mensual</option>
}                

{this.props.curso.cuota !=="mes" && <option value="4"> Totalidad</option>
}




                 </select>
       
                </div>



<div className="form-group">
                  <label htmlFor="tipopago">Forma de Pago</label>
                 <select className="form-control"
                    name="tipopago"
                    value={this.state.tipopago || null}
                    onChange={this.onChange.bind(this)}>
                <option > Seleccionar</option>

                <option value="pm"> Pago Movil</option>
                <option value="t"> Transferencia (Bs)</option>
                <option value="e"> Efectivo</option>


                 </select>
       
                </div>

  {tipopago ===  "pm" ?<div  className="col-sm-12">
      <div className="card-body">
        <h5 className="card-title">Pago Movil</h5>
        <p className="card-text"><strong>Teléfono: </strong> 04244006995 </p>
        <p className="card-text"><strong>Cedula: </strong>  21242299 </p>
        <p className="card-text"><strong>Banco: </strong> Banco de Venezuela </p>
      </div>

  


      <div className="form-group">
                  <label htmlFor="fechadeposito">Fecha de Emision</label>
           
                    
           <input   type="date"  
                    value={this.state.fechadeposito}
                    className="form-control"
                    name="fechadeposito"   onChange={this.onChange.bind(this)}></input>
                </div>



      <div className="form-group">
                  <label htmlFor="vauche">Nro Referencia</label>
           

           <input   type="text"  
                    className="form-control"
                    name="vauche"   onChange={this.onChange.bind(this)}></input>
                </div>
              
                <div className="form-group">
                  <label htmlFor="pagobs">Monto transferido en Bolivares (Recibimos a tasa BCV del dia)</label>
           

           <input   type="number"  step="0.01"
                    className="form-control"
                    name="pagobs"   onChange={this.onChange.bind(this)}></input>
                </div>
                <div className="form-group">
                  <label htmlFor="nota">Nota:</label>
           

           <input   type="text"  
                    className="form-control"
                    name="nota"   onChange={this.onChange.bind(this)}></input>
                </div>






  </div>:null}
  {tipopago === "t" ?<div  className="col-sm-12">
      <div className="card-body">
        <h5 className="card-title">Tranferencia</h5>
        <p className="card-text"> Banco Nacional de Crédito</p>    
        <p className="card-text"> <strong>Cuenta N°: </strong>  0191-0127-48-2100070103</p>
        <p className="card-text"> <strong>Titular: </strong>ArteyEfectos Producciones CA</p>
        <p className="card-text"> <strong>Rif: </strong> J-40760857-6</p>
        <p className="card-text"> <strong>Correo: </strong> arteyefectospro@gmail.com</p>
      </div>




      <div className="form-group">
                  <label htmlFor="fechadeposito">Fecha de Emision</label>
           
                    
           <input   type="date"  
                    value={this.state.fechadeposito}
                    className="form-control"
                    name="fechadeposito"   onChange={this.onChange.bind(this)}></input>
                </div>


                <div className="form-group">
                  <label htmlFor="bancoemisor">Banco Emisor</label>
           

           <input   type="text"  
                    className="form-control"
                    name="bancoemisor"   onChange={this.onChange.bind(this)}></input>
                </div>


                <div className="form-group">
                  <label htmlFor="titular">Nombre de Titular</label>
           

           <input   type="text"  
                    className="form-control"
                    name="titular"   onChange={this.onChange.bind(this)}></input>
                </div>


      <div className="form-group">
                  <label htmlFor="vauche">Nro Referencia (Vauche)</label>
           

           <input   type="text"  
                    className="form-control"
                    name="vauche"   onChange={this.onChange.bind(this)}></input>
                </div>
              
                <div className="form-group">
                  <label htmlFor="pagobs">Monto transferido en Bolivares (Recibimos a tasa BCV del dia)</label>
           

           <input   type="number"   step="0.01"
                    className="form-control"
                    name="pagobs"   onChange={this.onChange.bind(this)}></input>
                </div>

                <div className="form-group">
                  <label htmlFor="nota">Nota:</label>
           

           <input   type="text"  
                    className="form-control"
                    name="nota"   onChange={this.onChange.bind(this)}></input>
                </div>

 










  </div>:null}

  {tipopago === "e" ?<div  className="col-sm-12">
      <div className="card-body">
        <h5 className="card-title">Efectivo</h5>
        <p className="card-text">Los pagos en efectivo deben ser llevados a la Escuela en máximo 3 días hábiles de nuestro horario de atención (Martes a Sábado 9:00am - 4:00pm). Coordinar a través del número de teléfono +58 414-6910653</p>
      
      
        <div className="form-group">
                  <label htmlFor="pagobs">Monto pagado en Divisa (USD)</label>
           

           <input   type="number"   step="0.01"
                    className="form-control"
                    name="pagousd"   onChange={this.onChange.bind(this)}></input>
                </div>
                <div className="form-group">
                  <label htmlFor="nota">Nota:</label>
           

           <input   type="text"  
                    className="form-control"
                    name="nota"   onChange={this.onChange.bind(this)}></input>
                </div>
      </div>
  </div>:null}

  
  <strong>Total a Pagar:</strong>
{this.montopagar()} 

 
{tipopago !=="Seleccionar" &&<button  disabled={this.state.boton} type="submit" className="btn btn-info">Reportar Pago</button>

} <p className="py-2">{this.state.message}</p> </form>
<br></br>
<h6>Terminos y Condiciones:</h6>
<li>Las Cuotas o Mensualidades, deben ser canceladas puntualmente los primeros 5  días del mes. Cualquier retraso implicara un cargo adicional.</li>
<li>Los precios están sujeto a cambios sin previo aviso.</li>
<li>No se realizaran rembolsos de ningún tipo.</li>
<li>Solo podrán permanecer en el establecimiento, los estudiantes activos. Se reserva el derecho de admisión.</li>


</div>

</div>:  <Link to={"/pagos"} >  <p className="container py-3 btn-primary">El reporte de pago fue enviado con éxito. Verificaremos la información suministrada y nos pondremos en contacto con usted. Puede hacer seguimiento a su reporte en el carrito de compra.                
  <i className="fas fa-shopping-cart" />
</p></Link>
}
      </div>;
    }
  }

