import React, { Component } from "react";
import { Navigate,Link } from "react-router-dom";
import UserService from "../../services/user.service";
import authService from "../../services/auth.service";
import adminService from "../../services/admin.service"


//import "../styles.profile.css";

import Modal from 'react-modal';
//import LoginComponent from "./login.component";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',

  },
};

export default class admincomponents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: null,
      content:null,
      mensajes:null,
      novedades:[],
      exclusivo:[{titulo:"Realizacion de Casting",descripcion:"Nuevo casting para actores y demas bal bla bla",fechainicio:"",img:"images/1.jpeg"},{titulo:"Estudios Integrales de Cine y television",descripcion:"Un nuevo curso esta por empezar",fechainicio:"",img:"images/1.jpeg"},{titulo:"Inaugurado nuevo espacio",descripcion:"resumen",fechainicio:"",img:"images/1.jpeg"},{titulo:"hola",descripcion:"resumen",fechainicio:"",img:"images/1.jpeg"}],
      trabajos:[{titulo:"Realizacion de Casting",descripcion:"Nuevo casting para actores y demas bal bla bla",fechainicio:"",img:"images/1.jpeg"},{titulo:"Estudios Integrales de Cine y television",descripcion:"Un nuevo curso esta por empezar",fechainicio:"",img:"images/1.jpeg"},{titulo:"Inaugurado nuevo espacio",descripcion:"resumen",fechainicio:"",img:"images/1.jpeg"},{titulo:"hola",descripcion:"resumen",fechainicio:"",img:"images/1.jpeg"}],

     
     
      seleccion:null


    };
  }

  componentDidMount() {


 

    adminService.getcursos().then(
      response => {
        this.setState({
          content: response.data
        })});
  







  
  }

  render() {


    const { currentUser } = this.state;
  
    if (this.state.redirect) {
        return <Navigate to={this.state.redirect} />
      }
 
 
   
    return (
        <div>
<div className="hero">
  <header>
    <nav className="navbar">
      <div className="navbar__brand">
      <Link to={"/profile"} >

        <img src="https://arteyefectos.com/estudios/images/logo_escuela_aye_sd.png" alt="logo" className="brand__logo" />  <p><i className="fa fa-undo" aria-hidden="true" />
Regresar
 </p>    </Link >

      </div>
      <div className="navbar__nav__items">
        <div className="nav__item">
      
    
        </div>

      </div>
    </nav>
  </header>
        
      <div className="hero__bg__image__container">
        <img src="../img/fondoweb.png" alt="BG hero image" className="hero__bg__image" />
      </div>
      
      <div className="hero__bg__overlay" />
      <div className="hero__card2 container">
       
      
        <div className="card card-container ">
        <h3><i className="fas fa-shopping-cart" />  Cursos Disponibles: </h3>

        
  
        </div>





<div className=" row ">



   
    {this.state.content? this.state.content.map((item,index)=>
    
  

<div key={index} className="card" style={{width: '18rem'}}>
  <img className="card-img-top" src={item.img} alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title">#{item.id} - {item.titulo}</h5>
    <p className="card-text">{item.tipocurso}</p>
    <p className="card-text">{item.materias}</p>
    <p className="card-text">{item.resumen}</p>
    <p className="card-text">{item.horario}</p>
    <p className="card-text">{item.turno}</p>
    <p className="card-text">{item.salon}</p>
    <p className="card-text">{item.capacidad}</p>
    <p className="card-text">{item.costoinscrip}</p>

    <p className="card-text">{item.costousd} USD</p>
    <p className="card-text">{item.fehainicio}</p>
    <p className="card-text">{item.fechafinal}</p>
    <p className="card-text">{item.clases} Clases</p>
    <p className="card-text">{item.cuota}</p>
    <p className="card-text">{item.cuota}</p>

    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <select >
      <option  selected ={item.estatus == "ac"?true:false} value="ac">Activo</option>
      <option selected ={item.estatus == "in"?true:false} value="in">Inactivo</option>
      <option  selected ={item.estatus == "fin"?true:false}  value="fin">Finalizado</option>
      <option  selected ={item.estatus == "full"?true:false}  value="full">Full</option>
      </select>
      <button className="btn btn-primary" onClick={()=>{this.setState({seleccion:item});console.log(this.state.seleccion)}}>Editar</button>
      <button onClick={()=> {if(window.confirm('Delete the item?')){adminService.deletecursos(item.id)};}} className="btn btn-danger">Borrar</button>


</div>

</div>





    




    
    ):<p>Cargando...</p>}
  





      </div>












      </div>
    </div>

        </div>
    );
  }
}





















/* 
    componentDidMount() {
       

        adminService.getcursos().then(
            response => {
              this.setState({
                content: response.data
              })});
        
    }
    




 */