import axios from "axios";

//const API_URL = "http://localhost:8000/api/";


//serverhosting
const API_URL = '/api/';



class AuthService {
 
 
 
  login(username, password) {



    let okey = false
  //  console.log(username, password)
    return axios
      .post(API_URL + "login", {
        email:username,
        password
      })
      .then(response => {




        if (response.data.accessToken) {
        
        
          localStorage.setItem("user", JSON.stringify(response.data));

        okey = true  

        }

        return response.data;
      }).catch(err =>
        
        
        
    {    console.log(err)
    
    okey=false


    return okey
    
    });



  }

  logout() {
    localStorage.removeItem("user");
  }

  
  
  register(uuid,name,
  email,
  password,
  cedula,
  telefono,
  telefono2,
  nombrefamilia,
  sexo,
  redes,
  nacimiento,
  discapacidad,
  nacionalidad,
  direccion,

  referencia) {


    return axios.post(API_URL + "register", {uuid,
      name,
      email,
      password,

      cedula,
      telefono,
      telefono2,
      nombrefamilia,
      sexo,
      redes,
      nacimiento,
      discapacidad,
      nacionalidad,
      direccion,

      referencia,
    }).catch((err)=>{
      console.log(err)
     
      return "Hay un Error"
      
      });
  }

  getCurrentUser() {
 
    return JSON.parse(localStorage.getItem('user'));;


  }
}

export default new AuthService();