import React, { Component } from "react";
import { Navigate,Link } from "react-router-dom";
import UserService from "../services/user.service";
import authService from "../services/auth.service";
import "./styles.profile.css";
import Pagar from "../components/pagar.component"
import Modal from 'react-modal';



export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: null,
      content:null,
      contenido:null,
      mensajes:null,
      novedades:[],
trabajos:[],
     modalVideo:null,
     modalmensajes:false,
      seleccion:null,
      tiempo:1,
      errores:null,
      home:this.props.home,
      paginaactual:0,
      paginaactualcortos:0


    };
  }

  componentDidMount() {




    //utiliza el token para conseguir datos de usuario
    UserService.getUserBoard().then(
      response => {
        this.setState({
          currentUser: response.data,
          userReady: true,
          mensajes: response.data.notificacionusuario

        });


      },
      error => {

//si no lo consigues, solicita login

        this.setState({
          userReady: false,
          errores:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
   



    UserService.getPublicContent().then(
      response => {
        this.setState({
          novedades: response.data.cursos,

          trabajos: response.data.trabajos


        });

      // console.log(response)

      },
      error => {
        this.setState({
          errores:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  





  
  }

  render(){
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { currentUser,seleccion,mensajes,home,userReady,paginaactual,paginaactualcortos } = this.state;

    return (
      <>

{!home?
     <div>

  <div className="navbar2">
    <div className="navbar2-container">
      <div className="logo2-container">

      <Link to={"/"} >

        <img src="../img/logo_escuela_aye_sd.png" className="logo2" alt="Escuela de Cine" />                </Link>

      </div>
      <div className="menu2-container">
        <ul className="menu2-list">
          <li className="menu2-list-item active">Inicio</li>
{/*           <li className="menu2-list-item">Cursos</li>
          <li className="menu2-list-item">Contenido</li>
 */}

   {/*        
          <li className="menu2-list-item">Novedades</li>
          <li className="menu2-list-item">Filmmaker</li>
 */}

          
        </ul>
      </div>
      
      {this.state.currentUser? <div className="profile2-container">
      <div className="profile2-text-container">
          <span className="profile2-text">{currentUser.name}</span>

     
        </div>
        
                <button  className="btn btn-primary" onClick={()=> this.setState({modalmensajes:true})}><i className="fa fa-envelope" /> {this.state.mensajes.length>0 && this.state.mensajes.length}</button>
                <Link to={currentUser.nivel? "/ad":"/pagos"} className="nav-link">  
        <button  className=" btn btn-info  mx-1"><i className="fas fa-shopping-cart" /> </button>
        </Link>

        <button onClick={()=>{authService.logout() ; this.setState({redirect:"/home",currentUser:undefined,userReady:false});
}} className=" btn btn-danger "><i className=" fa fa-sign-out" aria-hidden="true"></i></button>

        
      </div>:<div className="nav__item">
        <Link to={"/login"} className="nav-link">    <button className="signin__button">Login</button>                 
                </Link>



        </div>}
    </div>
  </div>
  {/* Sidebar Section */}
  <div className="sidebar2">
 {/*    
    <i className="left-menu-icon fas fa-search" /> */}
     <Link to={"/home"} className="nav-link">  
    <i className="left-menu-icon fas fa-home" />
</Link>


    <i className="left-menu-icon  fa fa-graduation-cap" />
    <i className="left-menu-icon fa fa-film" aria-hidden="true"></i>
  
  
{/*     <i className="left-menu-icon fa fa-info-circle" />
    <i className="left-menu-icon fas fa-users" />
     */}

{userReady &&   <Link to={"/pagos"} className="nav-link">  
    <i className="left-menu-icon fas fa-shopping-cart" />
  </Link>
  }
  
  </div>
 

{/*  CONTENIDO DE LA PAGINA 
 */} 
 
  <div className="container2">
  <div className="content2-container">

  



  
<div className=" bienvenida col">
<h1 className="text-center">¡Bienvenidos!</h1>
<h4 className="text-center">Se parte de la comunidad y disfruta de todo el contenido exclusivo que tenemos para ti.</h4>
{/* <button className="btn btn-primary py-2 my-4">
Entrar a la Comunidad</button> */}


      </div>

<img src="https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2Ffondoweb.png?alt=media&token=220014d9-8eb4-414d-9080-ecc38671d18e" alt="fondo" className=" imagendefondo" />

    




      <div className="movie2-list-container col">
            <h1 className="movie2-list-title">NUEVOS CURSOS     
</h1>
              <div className="movie2-list-wrapper">
                <div className="movie2-list">


{paginaactual>0 &&                <button  className=" botonanterior"   onClick={()=>this.setState({paginaactual:paginaactual-1})}><i className="fa fa-arrow-circle-left" aria-hidden="true"></i></button>
}
                 {this.state.novedades? this.state.novedades.slice(this.state.paginaactual,this.state.paginaactual+6).map(((item,index)=> index <5 ? <div key={index} className="movie2-list-item"  onClick={()=>this.setState({seleccion:item, modalOpen:true})}>
                    <img alt="Curso" src={item.img} className="movie2-list-item-img" />
                    <span className="movie2-list-item-title">{item.titulo}</span>
                    <p className="movie2-list-item-desc">
                   {item.resumen}
                    </p>
                    <button onClick={()=>this.setState({seleccion:item, modalOpen:true})} className="movie2-list-item-button "><i className="fa fa-hand-pointer-o" aria-hidden="true"></i> Información</button>
                  
                  
                  
                  
                  </div> :
                  
                  
                  <div  key={index}  className="movie2-list-item" onClick={()=>this.setState({paginaactual:paginaactual+1})}>
                    
      
                    <img alt="Curso" src={item.img} className="movie2-list-item-img" />
                    <button  className=" botonsiguiente"   onClick={()=>this.setState({paginaactual:paginaactual+1})}><i className="fa fa-arrow-circle-right" aria-hidden="true"></i></button>
               </div>)):<p><span className="spinner-border spinner-border-sm"></span>  Cargando...</p>} 
                  
                  
                </div>
              </div>
            </div>















      {/* Featured Movie Section  */}
      <div className="movie2-list-container">
        
      <h1 className="movie2-list-title">PROYECTOS DE ESTUDIANTES</h1>
        <div className="movie2-list-wrapper">
          <div className="movie2-list">
           
          
          {paginaactualcortos>0 &&                <button  className=" botonanterior"   onClick={()=>this.setState({paginaactualcortos:paginaactualcortos-1})}><i className="fa fa-arrow-circle-left" aria-hidden="true"></i></button>
}
       {this.state.trabajos? this.state.trabajos.slice(this.state.paginaactualcortos,this.state.paginaactualcortos+6).map(((item,index)=> index <5 ? <div key={index} className="movie2-list-item"    >
              <img alt="Trabajos" src={item.img} className="movie2-list-item-img" />

              <span className="movie2-list-item-title">{item.titulo}</span>
              <p className="movie2-list-item-desc" onClick={()=>this.setState({modalVideo:index})} >
             {item.resumen}
              </p>
              <button onClick={()=>this.setState({modalVideo:index})} className="movie2-list-item-button "><i className="fa fa-play" aria-hidden="true" /> Reproducir</button>
            
            

{/* MODAL DE VIDEOS
 */}

              {this.state.modalVideo === index &&   <div  className="videoyoutube"> 
  
  <iframe title={item.titulo} className="videoyoutube" src={'https://www.youtube.com/embed/'+item.idyoutube+'?mute=0&autoplay=1&loop=1&controls=1'} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;loop" allowfullscreen></iframe>
  <div className="detallesvideo">
  <h3 className="mx-4 my-2">{item.titulo} </h3>
  <p className="mx-4">{item.contenido} </p>
  <button onClick={() => this.setState({ modalVideo: null })} className="btn btn-danger fixed-bottom "><i className="fa fa-times-circle" aria-hidden="true" /> Cerrar</button>

  </div>
  </div>}



            
            
            </div> :
            
            
            <div  key={index}  className="movie2-list-item" onClick={()=>this.setState({paginaactualcortos:paginaactualcortos+1})}>
              

              <img alt="Trabajos" src={item.img} className="movie2-list-item-img" />
              <button  className=" botonsiguiente"   onClick={()=>this.setState({paginaactualcortos:paginaactualcortos+1})}><i className="fa fa-arrow-circle-right" aria-hidden="true"></i></button>

              <button className="movie2-list-item-button "><i className="fa fa-eye" aria-hidden="true" /> Ver Más</button>
       </div>)):<p> <span className="spinner-border spinner-border-sm"></span> Cargando...</p>} 




       
          </div>
        </div>



      </div>
      {/* Banner Section  */}
    
      {/* Featured Movie Section  */}
      <div className="movie2-list-container py-3">
   

      </div>


    </div>


  </div>



</div>:

/* Muestra esto en home*/

<div className="movie2-list-container col">
<h1 className="movie2-list-title">NUEVOS CURSOS     
</h1>
  <div className="movie2-list-wrapper">
    <div className="movie2-list">


{paginaactual>0 &&                <button  className=" botonanterior"   onClick={()=>this.setState({paginaactual:paginaactual-1})}><i className="fa fa-arrow-circle-left" aria-hidden="true"></i></button>
}
     {this.state.novedades? this.state.novedades.slice(this.state.paginaactual,this.state.paginaactual+6).map(((item,index)=> index <5 ? <div key={index} className="movie2-list-item"  onClick={()=>this.setState({seleccion:item, modalOpen:true})}>
        <img alt="Curso" src={item.img} className="movie2-list-item-img" />
        <span className="movie2-list-item-title">{item.titulo}</span>
        <p className="movie2-list-item-desc">
       {item.resumen}
        </p>
        <button onClick={()=>this.setState({seleccion:item, modalOpen:true})} className="movie2-list-item-button ">Información</button>
      
      
      
      
      </div> :
      
      
      <div  key={index}  className="movie2-list-item" onClick={()=>this.setState({paginaactual:paginaactual+1})}>
        

        <img alt="Curso" src={item.img} className="movie2-list-item-img" />
        <button  className=" botonsiguiente"   onClick={()=>this.setState({paginaactual:paginaactual+1})}><i className="fa fa-arrow-circle-right" aria-hidden="true"></i></button>
   </div>)):<p><span className="spinner-border spinner-border-sm"></span>  Cargando...</p>} 
      
      
    </div>
  </div>
</div>

}



{/* Modales */}
<div>
          <Modal
            isOpen={this.state.modalOpen}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}

            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
              },
              content: {
                backgroundColor: 'rgba(0, 0, 0, 1)'

              }
            }}
          >
            <button onClick={() => this.setState({ modalOpen: false })} className=" btn btn-danger fixed-bottom "><i className="fa fa-times-circle" aria-hidden="true" /> Cerrar

</button>




            {seleccion ?
              <div class="row  ">
                <div class=" ">
                  <img alt="Imagen del Curso" className="col imagencurso" src={seleccion.img} />
                </div>
                <div class="col">
                  <h3 className="feature__title">{seleccion.titulo}</h3>
                  <p className="">
                    {seleccion.contenido}          </p>
                  <div className="card cardbody">
                    <h4>Precios:</h4>
                   


                    <table class="table table-striped table-dark">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
  {seleccion.costoinscrip>0 &&  <tr>
      <td scope="row">+ Inscripción</td>
      <td >{seleccion.costoinscrip} USD</td>
    </tr>}
    {seleccion.totalusd>0 ? <tr>
      <th scope="row">+ Precio del Curso</th>
      <td>{seleccion.totalusd} USD</td>
    </tr>:<tr>
      <th scope="row">+ Precio de la Mensualidad</th>
      <td>{seleccion.costousd} USD</td>
    </tr>}
    <tr className="bg-success">
      <th   >TOTAL DE INVERSION</th>
{seleccion.totalusd>0 ?      <td>{seleccion.costoinscrip+seleccion.totalusd} USD</td>: <td>{seleccion.costoinscrip+seleccion.costousd} USD</td>
}    </tr>
  </tbody>
</table>



{seleccion.cuota !=="mes" &&  seleccion.costousd>0 &&  <p><i className="fa fa-money " aria-hidden="true" /> Opcion de Pago a Cuotas Mensuales: {seleccion.costousd} USD</p>
}                   

{seleccion.descuento && <h4 className="text-center card">
<i className="fa fa-gift py-2" aria-hidden="true" /> Paga por Anticipado y Obtén un {seleccion.descuento}% = {seleccion.costoinscrip + seleccion.totalusd - (seleccion.totalusd*seleccion.descuento)/100} USD </h4> 
}
                    

                    <p></p>

                  </div>
                </div>
              </div>: null}


            {!currentUser ? <div className="iraregistro  feature__sub__title">
              <div className="row container">
                <p className="">(*) Para inscribirte necesitas estar registrado en el portal:<Link to={"/register"}> <button className="btn btn-secondary"> <i className="fa fa-address-card" aria-hidden="true" /> Ir al Registro</button></Link>
                  <Link to={"/login"} >
                    <button className="btn btn-primary mx-1 px-1 "><i className="fa fa-sign-in" aria-hidden="true"></i> Login </button></Link>
                </p>
              </div>  </div> : <Pagar name={currentUser.name} currentUser={currentUser} curso={seleccion} reporta="estudiante"></Pagar>
            }
          </Modal>





          <Modal
            isOpen={this.state.modalmensajes}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}

            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
              },
              content: {
                backgroundColor: 'rgba(0, 0, 0, 1)'

              }
            }}

>
            <button onClick={() => this.setState({ modalmensajes: false })} className="btn btn-danger fixed-bottom "><i className="fa fa-times-circle" aria-hidden="true" /> Cerrar</button>

            <h2>Bandeja de Mensajes</h2>
            {currentUser ? <div className=" ">

              {mensajes.length > 0 ? mensajes.map((item, index) =>
                <div className="card">
                  <div className="card-header">
                    {item.fechaenvio}
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{item.asunto}</h5>
                    <p className="card-text">{item.mensaje}</p>
                    <button className="btn btn-danger">Eliminar</button>
                  </div>
                </div>) : <li>No has recibido ningún mensaje hasta ahora.</li>}
            </div> : <p>Por ahora no tienes mensajes por leer</p>}
          </Modal>


    </div>



      </>
    );
  }
}
