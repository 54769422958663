import React from 'react';

import  { Component } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";


import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import UserService from "./services/user.service";
import Carrito from "./components/carrito.component";
import BoardAdmin from './components/board-admin.component';



class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      userReady:false,
      content:null,

    };
  }

  componentDidMount() {


    //trae el contenido publico
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });

   //   console.log(response)

      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );






    
    //utiliza el token para conseguir datos de usuario
    UserService.getUserBoard().then(
      response => {
        this.setState({
          currentUser: response.data,
          userReady: true
        });

       // console.log(response)

      },
      error => {

//si no lo consigues, solicita login

        this.setState({
          userReady: false,
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
   




    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div className=''>



        <div className="listado ">
          <Routes>
            <Route path="/" element={<Home />} />
           
           
            <Route path="/home" element={<Home />} />
            
            <Route path="/login" element={<Login />} />
            <Route path="/pagos" element={<Carrito  />} />
            <Route path="/register" element={<Register />} />
            <Route path="/inicio" element={<Profile />} />
            <Route path="/ad" element={<BoardAdmin />} />

          </Routes>
        </div>

       <AuthVerify logOut={this.logOut}/>
      </div>
    );
  }
}

export default App;
