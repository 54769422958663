import React, { Component } from "react";
import { Navigate,Link } from "react-router-dom";
import UserService from "../services/user.service";
import "./styles.profile.css";



export default class Carrito extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: null,
      content:null,
      mensajes:null,
      novedades:[],
   
     
      seleccion:null


    };
  }

  componentDidMount() {


   

    //utiliza el token para conseguir datos de usuario
    UserService.getUserCarrito().then(
      response => {
        this.setState({
          content: response.data,
          userReady: true
        });

    //    console.log("Esto es el carrito",response)

      },
      error => {

//si no lo consigues, solicita login

        this.setState({
          userReady: false,
          redirect:"/login",
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
   

  
  }

  render() {


  
    if (this.state.redirect) {
        return <Navigate to={this.state.redirect} />
      }
 

   
    return (
        <div>



{this.state.userReady?
<div className="hero container">
  <header>
    <nav className="navbar">
      <div className="navbar__brand">
      <Link to={"/inicio"} >

        <img src="../img/logo_escuela_aye_sd.png" alt="logo" className="brand__logo" />  <p><i className="fa fa-undo" aria-hidden="true" />
Regresar
 </p>    </Link >

      </div>
      <div className="navbar__nav__items">
        <div className="nav__item">
      
    
        </div>

      </div>
    </nav>
  </header>
        
  
      
      <div className="hero__bg__overlay" />
      <div className="hero__card2 container">
       
      
        <div className="card card-container ">
        <h3><i className="fas fa-shopping-cart" />  Pagos Realizados: </h3>

        
  
        </div>
      
        <table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">Fecha</th>
      <th scope="col">Curso</th>
      <th scope="col">Concepto</th>

      <th scope="col">Monto Pagado</th>
      <th scope="col">Estatus</th>
    </tr>
  </thead>
  <tbody>
   
   {this.state.content.length >0? this.state.content.map((item)=>
   
   <tr className={item.estatus==="si"?"btn-success":""} >
      <th scope="row">{item.created_at}</th>
      <td>{item.titulo}</td>
      <td>{item.concepto}</td>

      <td>   
      {item.pagousd>0 ? <p>{item.pagousd} USD</p>:<p>{item.pagobs} Bs</p>}


      
      </td>
      <td>
        {item.estatus==="si" && <p className="btn btn-success">Verificado</p>}
        {item.estatus==="no" && <p className="btn btn-danger">No Validado</p>}
        {item.estatus===null && <p className="btn btn-info">Pendiente</p>}
        {item.estatus==="pe" && <p className="btn btn-info">Pendiente</p>}

        
        </td>

    </tr>
   
   
   
   ):<tr>No has reportado ningun pago aún.</tr>}
   
    
    
  </tbody>
</table>

      </div>
    </div>:
<div>
<span className="spinner-border spinner-border-sm"></span>  Cargando...
      <Link to={"/inicio"} >

        <p><i className="fa fa-undo" aria-hidden="true" />
Regresar
 </p>    </Link >

     


</div>}

<div className="hero__bg__image__container ">
        <img src="https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2Ffondoweb.png?alt=media&token=220014d9-8eb4-414d-9080-ecc38671d18e" alt="fondo" className="hero__bg__image" />
      </div>
        </div>
    );
  }
}
