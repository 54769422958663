import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import uuid from 'react-uuid';
import { Link } from "react-router-dom";
import { withRouter } from '../common/with-router';

import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Ingresa un Email Valido
      </div>
    );
  }
};

const vname = value => {
  if (value.length < 3 || value.length > 120) {
    return (
      <div className="alert alert-danger" role="alert">
 Tu Nombre y Apellido debe tener mas de 3 caracteres
       </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
 La contraseña debe tener al menos 6 caracteres
       </div>
    );
  }
};





 class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      name: "",
      email: "",
      password: "",
      cedula: "",
      telefono: "",
      telefono2: "",
      nombrefamilia: "",
      sexo: "m",
      redes: "",
      nacimiento: null,
      discapacidad: "",
      nacionalidad: "v",
      direccion: "",
      referencia: "",


 successful: false,
      message: "",
      valido:false,
loading:false

    };
  }


  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });



    
  }

  onChangeUsername(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      loading:true
    });


       this.form.validateAll();

//verifica si hay campos vacios
    if (this.state.cedula,
      this.state.telefono,
      this.state.telefono2,
      this.state.nombrefamilia,
      this.state.sexo,
      this.state.redes,
      this.state.nacimiento,
      this.state.nacionalidad,
      this.state.direccion && this.checkBtn.context._errors.length === 0) {


        AuthService.register(
  
          uuid(),
          this.state.name,
          this.state.email,
          this.state.password,
          this.state.cedula,
          this.state.telefono,
          this.state.telefono2,
          this.state.nombrefamilia,
          this.state.sexo,
          this.state.redes,
          this.state.nacimiento,
          this.state.discapacidad,
          this.state.nacionalidad,
          this.state.direccion,
          this.state.referencia,
  
        ).then(
          response => {
  
console.log("esto recibe del registro",response)
     

if(response==="Hay un Error"){

  this.setState({
    loading:false,
    successful: false,
    message: "Hay un Error, Intente con otro correo y si no funciona reporta el error con el administrador"
  
  })              
            }else{



 AuthService.login(this.state.email, this.state.password).then(
  
  
  () => {

    this.props.router.navigate("/inicio");
    window.location.reload();
    this.setState({
      successful: true,
      loading:false


    });

  });;



            }




          
     
   
    
          }
        ).catch((err)=>{


this.setState({
  loading:false,
  successful: false,
  message: "Hay un Error, Intente con otro correo y si no funciona reporta el error con el administrador"

})
          
        });

   
   
   
   
   
    } else {

      this.setState({ message: "No puede dejar ningun campo vacio",loading:false })

    }








  }

  render() {
    return (

<div className="hero ">
<header>
    <nav className="navbar">
      <div className="navbar__brand">
      <Link to={"/"} >

        <img src="../img/logo_escuela_aye_sd.png" alt="logo" className="brand__logo" />      </Link >

      </div>
      <div className="navbar__nav__items">
        <div className="nav__item">
      
    
        </div>

      </div>
    </nav>
  </header>
      <div className="hero__bg__image__container">
        <img src="https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2Ffondoweb.png?alt=media&token=220014d9-8eb4-414d-9080-ecc38671d18e" alt="fondo" className="hero__bg__image" />
      </div>
      <div className="hero__bg__overlay" />
      <div className="hero__card2 container">
       




          <Form className="card card-container"
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
<h3>Formulario de Registro</h3>
                   
          <img
            src="../img/cinema.png"
            alt="profile-img"
            className=""
          />
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="name">Nombre Completo Estudiante</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChangeUsername}
                    validations={[required, vname]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Correo Electronico</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>
             

                <div className="form-group">
                  <label htmlFor="password">Contraseña</label>
                  <Input
                    type="password"
                    placeholder="Para tu cuenta"

                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>




                <div className="form-group">
                  <label htmlFor="cedula">Cedula (DNI)</label>
                  <select className="col-xs-1 float-left "
                    name="nacionalidad"
                    value={this.state.nacionalidad}
                    onChange={this.onChange.bind(this)}>

                <option value="v"> Venezolano</option>
                <option value="e"> Extrangero</option>
                <option value="o"> Otro</option>


                 </select><Input
                    type="number"
                    className="form-control "
                    name="cedula"
                    value={this.state.cedula}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="telefono">Telefono (WS)</label>
                  <Input
                    type="tel"
                    placeholder="584240000000"
                    className="form-control"
                    name="telefono"
                    value={this.state.telefono}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="nacimientoaa">Fecha de Nacimiento</label>
                  <Input
                    type="date"
                    className="form-control"
                    name="nacimiento"
                    value={this.state.nacimiento}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="telefono2">Telefono de Emergencia</label>
                  <Input
                    type="tel"
                    className="form-control"
                    placeholder="De un Familiar o Amigo"
                    name="telefono2"
                    value={this.state.telefono2}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nombrefamilia">Nombre del Contacto</label>
                  <Input
                    type="text"
                    placeholder="De emergencia"
                    className="form-control"
                    name="nombrefamilia"
                    value={this.state.nombrefamilia}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="direccion">Direccion de Hogar</label>
                  <Input
                    type="text"
                    placeholder="Av,Calle,Manzana,Casa. Municipio"

                    className="form-control"
                    name="direccion"
                    value={this.state.direccion}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="discapacidad">¿Posee alguna Discapacidad o Condición?</label>
                  <Input
                    type="text"
                    placeholder="Ej. Alergico"
                    className="form-control"
                    name="discapacidad"
                    value={this.state.discapacidad}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="referencia">¿Como te enteraste del Curso?</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="referencia"
                    value={this.state.referencia}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sexo">Genero</label>
                 <select className="form-control"
                    name="sexo"
                    value={this.state.sexo}
                    onChange={this.onChange.bind(this)}>

                <option value="m"> Masculino</option>
                <option value="f"> Femenino</option>
                <option value="o"> Otro</option>


                 </select>
                 
                 
                 
                 
                 
              
                </div>
                <div className="form-group">
                  <label htmlFor="redes">Instagram</label>
                  <Input
                    type="text"
                    placeholder="@"

                    className="form-control"
                    name="redes"
                    value={this.state.resdes}
                    onChange={this.onChange.bind(this)}
                   
                  />
                </div>







                <div className="form-group">
                  <button className="btn btn-primary btn-block" disabled={this.state.loading}>  {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}Registrar</button>
                </div>
                {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
              </div>
            )}

           
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      
      



    </div>
      
      
    );
  }
}
export default withRouter(Register);