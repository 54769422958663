import React, { Component } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Vercursos from "./admincomponents/Vercursos";
import Pagar from "./pagar.component";
import { json } from "react-router-dom";
import { Navigate,Link } from "react-router-dom";

import Modal from 'react-modal';

export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      reportes:[],
      reporta:null,
      nivel:null,
      reportesEstudiante:[],
      selecuser:null,
      allreportes:null,
      estudiantes:[],
      cursos:[],
      selectEstudiante:null,
      selectCurso:null,
      modal:false,
      redirect:null,
      userReady:false,
      estudiante:null

      
    };
  }


  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
}   


 seleccionarcurso(e){

  const datosCurso = this.state.cursos[e.target.value]

  let filtrarCurso = this.state.allreportes.filter(item => item.idcurso === datosCurso.codigocurso)

  

if(this.state.reportesEstudiante.length>0){


   filtrarCurso = this.state.reportesEstudiante.filter(item => item.idcurso === datosCurso.codigocurso)



}else{



filtrarCurso = []

if(!this.state.estudiante){
  filtrarCurso = this.state.allreportes.filter(item => item.idcurso === datosCurso.codigocurso)

}

}

//si no hay estudiante seleccionado




  this.setState({

    reportes:filtrarCurso,
    selectCurso:datosCurso

  })

 }


 seleccionarestudiante(e){


  const datosEstudiante = this.state.estudiantes[e.target.value]

  this.setState({estudiante:datosEstudiante})
   
 const reportesdeEstudiante = this.state.allreportes.filter(item => item.userid === datosEstudiante.uuid)

 console.log(datosEstudiante)

 console.log(reportesdeEstudiante)
  this.setState({

    selectEstudiante: datosEstudiante,
    reportesEstudiante:reportesdeEstudiante,

    reportes:reportesdeEstudiante

  })


 }





  componentDidMount() {
  

 //utiliza el token para conseguir datos de usuario
 UserService.getAdminBoard().then(
  response => {
    this.setState({
      allreportes: response.data.reportes,
      estudiantes: response.data.estudiantes,
      cursos: response.data.cursos,
      reporta: response.data.reporta,
      nivel: response.data.nivel,

reportes: response.data.reportes,

      userReady: true,
    });


  },
  error => {

//si no lo consigues, solicita login

    this.setState({
      userReady: false,
      redirect:"/login",
      content:
        (error.response && error.response.data) ||
        error.message ||
        error.toString()
    });
  }
);


  } //fin montar




  render() {
    const {selecuser,reporta,nivel,selectEstudiante,selectCurso} = this.state

    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }


    return (

<>
{this.state.userReady?<div className="">
<h1>Panel Administrativo</h1>
<p>Hola, {reporta} ( {nivel} )</p>

<div className="container">

<h3>Filtrar y Realizar Reportes</h3>

<div className="row container">
 <select className="form-control col" name="reporte" onChange={(e)=>this.seleccionarestudiante(e)}>
<option key="-1"  value="ninguno">Seleccionar Estudiante</option>
{this.state.estudiantes.length >0? this.state.estudiantes.map((item,index)=><option  key={index}  value={index}>{item.name}</option>):null}
</select>




 <select className="form-control col mx-2" name="reporte" onChange={(e)=>this.seleccionarcurso(e)}>
<option value="ninguno" >Seleccionar Curso</option>
{this.state.cursos.length >0? this.state.cursos.map((item,index)=><option key={index} value={index}>{item.titulo}</option>):null}
</select>


</div>
<br></br>

<div className="row container">
<button className="btn btn-primary" onClick={()=> this.setState({reportes:this.state.allreportes})}>Mostrar Todos</button>

<button className="btn btn-info mx-2" onClick={()=>this.setState({modal:true})} disabled={this.state.selectEstudiante && this.state.selectCurso? false:true}>Reportar Pago</button>
<button className="btn btn-secondary mx-2" onClick={()=>    window.location.reload()} >Refrescar</button>

</div>
<br></br>
 
 
 
 <table className="table table-dark container">
  <thead>
    <tr>
      <th scope="col">Fecha</th>
      <th scope="col">Estudiante</th>

      <th scope="col">Curso</th>
      <th scope="col">Concepto</th>

      <th scope="col">Monto Pagado</th>
      <th scope="col">Estatus</th>
    </tr>
  </thead>
  <tbody>
   
   {this.state.reportes.length >0? this.state.reportes.map((item)=>
   
   <tr className={item.estatus==="si"?"btn-success":""} >
      <th scope="row">{item.created_at}</th>
   
      <td>{item.nombrecompleto}</td>

      <td>{item.titulo}</td>
      <td>{item.concepto}</td>

      <td>   
      {item.pagousd>0 ? <p>{item.pagousd} USD</p>:<p>{item.pagobs} Bs</p>}


      
      </td>
      <td>
        {item.estatus==="si" && <p className="btn btn-success">Verificado</p>}
        {item.estatus==="no" && <p className="btn btn-danger">No Validado</p>}
        {item.estatus===null && <p className="btn btn-info">Pendiente</p>}
        {item.estatus==="pe" && <p className="btn btn-info">Pendiente</p>}

        
        </td>

    </tr>
   
   
   
   ):<tr>No hay registros de pagos en esta relacion Estudiante / Curso.</tr>}






   
    
    
  </tbody>
</table>




</div>

<Modal
            isOpen={this.state.modal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}

            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
              },
              content: {
                backgroundColor: 'rgba(0, 0, 0, 1)'

              }
            }}

>
            <button onClick={() => this.setState({ modal: false })} className="btn btn-danger fixed-bottom "><i className="fa fa-times-circle" aria-hidden="true" /> Cerrar</button>

            <h2>Reportar Pago:</h2>
     {selectCurso && selectEstudiante &&     <div>    

<div className="card cardbody col-lg-6 container">
<h3>Nombre de Estudiante:</h3><h4 className="form-control ">{selectEstudiante.name} </h4>
<h3>Curso a Inscribir:</h3>
<h4  className="form-control "> {selectCurso.codigocurso} - {selectCurso.titulo}</h4>
<strong>Contenido:</strong><p> {selectCurso.contenido} </p>

</div>

       <Pagar currentUser={selectEstudiante} curso={selectCurso} reporta={reporta} ></Pagar>

</div>}
          </Modal>




      </div>:                  <span className="spinner-border spinner-border-sm"></span>
}

</>

    );
  }
}
