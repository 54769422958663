import React, { Component } from "react";
import { Navigate,Link } from "react-router-dom";

import UserService from "../services/user.service";
import "./home.component.css";
import Profile from "./profile.component";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser:this.props.currentUser,
      content: "",
      faq:"",
      publiblico:false,
      userReady:null

    };
  }

  componentDidMount() {
   



    var user = JSON.parse(localStorage.getItem('user'));

    if(user){
   
   
 this.setState({userReady:user , redirect:"/inicio"})
   //   console.log(user.username);
   }


    //trae el contenido publico
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data.cursos,
          publico:true
        });

   //   console.log(response)

      },
      error => {
        this.setState({
          publico:false,
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );



  }

  render() {

    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }




    return (
<div>
  <header>
    <nav className="navbar">
      <div className="navbar__brand">
        <img src="../img/logo_escuela_aye_sd.png" alt="logo" className="brand__logo" />
      </div>
      <div className="navbar__nav__items">
        <div className="nav__item">
      
    
        </div>
        <div className="nav__item">
      
{this.state.publico  && !this.state.userReady  &&       <Link to={"/login"} className="nav-link">    <button className="signin__button">Login</button>                 
                </Link>}
               
   {this.state.publico && this.state.userReady  &&     <Link to={"/inicio"} className="nav-link">    <button className="signin__button">Entrar</button>                 
                </Link>}


        </div>
      </div>
    </nav>
  </header>
  <main>
    <section className="hero">
      <div className="hero__bg__image__container">
        <img src="https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2Ffondoweb.png?alt=media&token=220014d9-8eb4-414d-9080-ecc38671d18e" alt="fondo" className="hero__bg__image" />
      </div>
      <div className="hero__bg__overlay" />
      <div className="hero__card">
        <h1 className="hero__title">
         Inicia en el Mundo Cinematográfico
        </h1>
        <p className="hero__subtitle">Aprende el Arte de Contar Historias y Trabajemos juntos en el Set de filmación.</p>

      {this.state.publico &&  <Link to={"/inicio"} >    <button className="primary__button"> <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>  Ingresar al Portal Web</button>                 
                </Link>}


{/*         
        <div className="email__form__container">
          <div className="form__container">
            <input type="email" className="email__input" placeholder=" " />
            <label className="email__label">Correo Electronico</label>
          </div>
          <button className="primary__button">
          Suscríbete <i className="fa-solid fa-chevron-right" />

          </button>
        </div> */}

      </div>

    </section>
 {this.state.publico &&    <Profile home="true"></Profile>
}
    <section className="features__container">


      {/* Feature 1 */}
      <div className="feature">
        <div className="feature__details">
          <h3 className="feature__title">La Escuela de Cine de Carabobo</h3>
          <h5 className="feature__sub__title">
    <strong>Arteyefectos Producciones C.A.</strong> Es una Casa Productora de Series y Películas. Amamos nuestro trabajo y nos gusta ayudar en la formación de nuevos talentos como tú.  
          </h5>
        </div>
        <div className="feature__image__container  videoyoutubehome">
          <iframe className="videoyoutubehome container" title="Demo Reel" src="https://www.youtube.com/embed/X5pWjporpz8?mute=1&autoplay=1&loop=1&controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;loop" allowfullscreen></iframe>
        </div>
      </div>
      {/* Feature 2 */}
      <div className="feature">
        <div className="feature__details">
          <h3 className="feature__title">
            Contamos con Equipos de Filmación Profesional
          </h3>
          <h5 className="feature__sub__title">
          Puedes alquilar nuestro estudio, luces y equipos para realizar producciones audiovisuales de cualquier resolución HD o 4k. Aprovecha los mejores paquetes para tus emprendimientos.          </h5>
        </div>
        <div className="feature__image__container">
          <img src="img/estudio_fotografico.jpg" alt="Estudio de Fotografia" className="feature__image" />
{/*          
          <div className="feature__2__poster__container">
            <div className="poster__container">
              <img src="img/camrafoto.png" alt="poster" className="poster" />
            </div>
            
            <div className="poster__details">
              <h4>Alquilar</h4>
              <p>Click para reservar</p>
            </div>
            
          </div>
 */}

        </div>
      </div>
      {/* Feature 3 */}
      <div className="feature">
        <div className="feature__details">
          <h3 className="feature__title">Tenemos un Personal Calificado</h3>
          <h5 className="feature__sub__title">
          Nuestro equipo de trabajo está conformado por profesionales en el área cinematográfica que hacen vida en la industria. Capaces de asumir cualquier reto y dispuestos a compartir su experiencia con nuestros estudiantes.  
          </h5>
        </div>
        <div className="feature__image__container feature__3__image__container">
          <img src="img/certificate.jpeg" alt="Personal" className="feature__image feature__3__image" />
         
        </div>
      </div>
      {/* Feature 4 */}
      <div className="feature">
        <div className="feature__details">
          <h3 className="feature__title">Conéctate con el medio</h3>
          <h5 className="feature__sub__title">
          Conoce a más talentos, inicia tus propios proyectos o forma parte de uno. Regístrate en nuestro portal web y conviértete en un miembro activo de nuestro gremio de realizadores.
          </h5>
        </div>
        <div className="feature__image__container">
        <img src="images/cine.jpg" alt="Conecta" className="feature__image feature__3__image" />
        </div>
      </div>
    </section>
    <section className="FAQ__list__container">
      <h1 className="FAQ__heading">Preguntas frecuentes</h1>

      <div className="FAQ__list">
        <div className="FAQ__accordian">
          <button className="FAQ__title" onClick={()=>this.setState({faq:1})}>
          ¿Qué es la Escuela de Cine?<i className="fal fa-plus" />
          </button>
          <div className={this.state.faq === 1?"":"FAQ__visible"}>
            <p>
            La Escuela de Cine Arteyefectos, es un espacio dedicado a la formación integral de nuevos
cineastas y realizadores audiovisuales, donde se brinda a nuestros estudiantes las
herramientas necesarias para hacer frente a cualquier proyecto audiovisual. Fomentamos la
creación de contenido tomando en cuenta las áreas fundamentales de la producción, así como,
la disciplina, el trabajo en equipo y la organización.


            </p>
            <p>
            Aprenderás el arte de contar historias capaces de generar emociones en los espectadores, al
mismo tiempo que conectas con la industria cinematográfica.
            </p>
          </div>
        </div>
        <div className="FAQ__accordian">
          <button className="FAQ__title" onClick={()=>this.setState({faq:2})}>
            ¿Donde Están Ubicados?<i className="fal fa-plus" />
          </button>
          <div className={this.state.faq === 2?"":"FAQ__visible"}>
            <p>
            Estamos ubicados en la Av. Bolívar Norte de Valencia, a la altura del Rectorado de la UC, en la Calle Kerdell, detrás del BNC. (Esquina anteriormente conocida como sede de FUNDAUC).

            </p>


<iframe title="Direccion de la Escuela" className="col"
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3926.734524790894!2d-68.00802554931973!3d10.202200872282754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e805d812d9d80cf%3A0xc05bfeecbe314285!2sEscuela%20de%20Cine%20Arteyefectos%20Producciones!5e0!3m2!1ses-419!2sve!4v1677593736266!5m2!1ses-419!2sve"
 height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

          </div>
        </div>
        <div className="FAQ__accordian" >
          <button className="FAQ__title" onClick={()=>this.setState({faq:3})}>
          ¿Qué cursos tienen disponibles?<i className="fal fa-plus" />
          </button>
          <div className={this.state.faq === 3?"":"FAQ__visible"}>
            <p>
Tenemos diferentes ofertas de estudios largos y cortos, estudios integrales, workshops, talleres, masterclass y seminarios impartidos por expertos en las áreas afines al audiovisual. Entre las ofertas disponibles contamos con:            </p>
<li>Estudios Integrales de Cine</li>
<li>Actuación para Cine y Televisión</li>
<li>Estudios Integrales de Fotografía</li>
<li>Estudios Integrales de Animación Digital y VFX</li>
          </div>
        </div>
        <div className="FAQ__accordian">
          <button className="FAQ__title" onClick={()=>this.setState({faq:4})}>
          ¿Cómo puedo inscribirme?<i className="fal fa-plus" />
          </button>
          <div className={this.state.faq === 4?"":"FAQ__visible"}>
            <p>
            Puedes inscribirte a través de nuestro portal web, créate un usuario y elige el curso en el que te deseas inscribir. Además de es aprovecha el contenido exclusivo y beneficios que tiene nuestro portal para nuestros estudiantes.            </p>
          </div>
        </div>
        <div className="FAQ__accordian">
        <button className="FAQ__title" onClick={()=>this.setState({faq:5})}>
        ¿Quién avala esta institución?<i className="fal fa-plus" />
          </button>
          <div className={this.state.faq === 5?"":"FAQ__visible"}>
            <p>
            ArteyEfectos Producciones C.A es una Casa Productora que cuenta con el registro en el CENTRO NACIONAL AUTONOMO DE CINEMATOGRAFIA,  es miembro y patrocinador oficial de los PREMIOS DE LA ACADEMIA DE CIENCIAS Y ARTES CINEMATOGRAFICAS DE VENEZUELA, fue fundada hace 8 años y tiene importante participación en 17 películas.           </p>
          </div>
        </div>
        <div className="FAQ__accordian">
          <button className="FAQ__title" onClick={()=>this.setState({faq:6})}>
          ¿Cómo puedo conseguir trabajo en la industria del cine?<i className="fal fa-plus" />
          </button>
          <div className={this.state.faq === 6?"":"FAQ__visible"}>
            <p>La industria cinematográfica en Venezuela está en constante crecimiento y cada vez se necesita de personal calificado. Nuestra escuela de cine, es un punto de encuentro en donde podrás hacer conexiones con la industria, empezar haciendo pequeños proyectos y contaras con el apoyo de una Casa Productora que hace vida en la industria cinematográfica, por lo que tendrás la oportunidad de estar en un set de rodaje.</p>
          </div>
        </div>
      </div>
      <div className="FAQ__get__started__email">
      <br></br>
      <a href="https://wa.me/584146910653?text=Me%20interesa%20el%20curso:" style={{clear: 'left', float: 'left', marginBottom: '1em', marginRight: '1em'}} rel="noreferrer">
        <h3 >
        ¿Tienes otra pregunta? Llama +58 414 6910653       </h3></a>
{/*         <div className="email__form__container">
          <div className="form__container">
            <input type="email" className="email__input" placeholder=" " />
            <label className="email__label">Correo Electronico</label>
          </div>
          <button className="primary__button">
            Suscribirse <i className="fa-solid fa-chevron-right" />
          </button>
        </div> */}
      </div>
    </section>
  </main>
  <footer>
    <div className="footer__row__1">
      <h4> ¡Inscribete! +58 414 6910653</h4>
    </div>

    <div className="footer__row__4">
      <p>Arteyefectos Producciones C.A - Rif J407608576 - Todos los derechos reservados (2023)</p>
    </div>
  </footer>
<div className="redes-flotantes">
  <div className="separator" style={{clear: 'both', textAlign: 'left'}}>
    <a href="https://wa.me/584146910653?text=Me%20interesa%20el%20curso:" style={{clear: 'left', float: 'left', marginBottom: '1em', marginRight: '1em'}} rel="noreferrer"><img alt="Ws" border={0} data-original-height={59} data-original-width={59} src="https://1.bp.blogspot.com/-q3Dot9N2qac/XOQgr9etVpI/AAAAAAABT1M/6V4Bqaqr-6UQcl9Fy2_CaVgex0N_OYuQgCLcBGAs/s1600/whatsapp%2Bicono.png" /></a></div>
</div>


</div>

    );
  }
}
