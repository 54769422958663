import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = 'http://localhost:8000/api/';

//serverhosting
const API_URL = '/api/';



class AdminService {
  
    getcursos() {
    return axios.get(API_URL + 'cursos');
  }

  deletecursos(id) {
    return axios.delete(API_URL + 'cursos/'+id);
  }

  getingresos() {
    return axios.get(API_URL + 'ingresos');
  }


  getUserBoard()  {
    return axios.get(API_URL + 'me', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new AdminService();
